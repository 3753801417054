
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Head from 'next/head';
import Login from 'components/signin/Login';
import { useTranslations } from 'hooks/translations/useTranslations';
import AccountPage from '../layouts/account';
const Sign = (props) => {
    const { home: { t }, } = useTranslations();
    return (<>
      <Head>
        <title>{t('login')}</title>
      </Head>
      <AccountPage {...props}>
        <Login />
      </AccountPage>
    </>);
};
export default Sign;

    async function __Next_Translate__getStaticProps__18e286372da__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18e286372da__ as getStaticProps }
  